import React, {ChangeEvent, FormEvent} from 'react';
import './App.scss';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table, Form} from "reactstrap";
import axios, {AxiosError, AxiosRequestConfig} from "axios";
import Fa from "../Fa";
import Swal from 'sweetalert2';

interface SearchForm {
    type: string;
    letter: string;
    minSimilarity: string;
    maxSimilarity: string;
}

interface Similar {
    letter: string;
    similarity: number;
}

interface State {
    form: SearchForm;
    working: boolean;
    similar: Similar[];
}

class App extends React.Component<any, State> {

    state: Readonly<State> = {
        form: {
            type: 'distance',
            letter: '',
            minSimilarity: '0',
            maxSimilarity: '100',
        },
        working: false,
        similar: [],
    };

    render(): React.ReactNode {
        return (
            <div className="App">
                <Container>
                    <Row>
                        <Col xs={{size: 6, offset: 3}}>

                            <Card className="mt-4 mb-4">
                                <CardBody>
                                    <Form onSubmit={e => this.onFormSubmit(e)}>

                                        <FormGroup>
                                            <Label>Type</Label>
                                            <Input type={'select'} name={'type'} value={this.state.form.type} onChange={e => this.onChangeInput(e)}>
                                                {/*<option value="similarity">Similarity</option>*/}
                                                <option value="distance">Distance</option>
                                            </Input>
                                        </FormGroup>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Letter</Label>
                                                    <Input
                                                        type={'text'}
                                                        name={'letter'}
                                                        required={true}
                                                        value={this.state.form.letter}
                                                        onChange={e => this.onChangeInput(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Min. {this.state.form.type === 'similarity' ? 'Similarity' : 'Distance'}</Label>
                                                    <Input
                                                        type={'number'}
                                                        name={'minSimilarity'}
                                                        min={0}
                                                        max={100}
                                                        required={true}
                                                        value={this.state.form.minSimilarity}
                                                        onChange={e => this.onChangeInput(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Max. {this.state.form.type === 'similarity' ? 'Similarity' : 'Distance'}</Label>
                                                    <Input
                                                        type={'number'}
                                                        name={'maxSimilarity'}
                                                        min={0}
                                                        max={100}
                                                        required={true}
                                                        value={this.state.form.maxSimilarity}
                                                        onChange={e => this.onChangeInput(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button type={'submit'} color={'primary'}>
                                            {this.state.working ? (
                                                <Fa icon={'sync'} fixedWidth={true} spin={true} className="mr-2"/>
                                            ) : false}
                                            Get Letters
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>

                            {this.state.similar.length ? (
                                <Card className={'mt-3'}>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th>Letter</th>
                                            <th>{this.state.form.type === 'similarity' ? 'Similarity' : 'Distance'}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.similar.map(similar => (
                                            <tr key={similar.letter}>
                                                <td>{similar.letter}</td>
                                                {this.state.form.type === 'similarity' ? (
                                                    <td>{100 - similar.similarity}%</td>
                                                ) : (
                                                    <td>{similar.similarity}</td>
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </Card>
                            ) : false}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    onChangeInput(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
        const input = e.currentTarget;
        this.setState(({form}) => ({
            form: {...form, [input.name]: input.value}
        }));
    }

    onFormSubmit(e: FormEvent): void {
        e.preventDefault();

        if (this.state.working) {
            return;
        }

        this.setState({working: true});

        const config: AxiosRequestConfig = {
            params: {
                ...this.state.form,
            },
        };

        const url = process.env.REACT_APP_API_URL + '/phoneme-similarity';

        axios.get<Similar[]>(url, config)
            .then(response => {
                this.setState({similar: response.data});
            })
            .catch((error: AxiosError) => {
                const message = error.response
                    ? error.response.data.message || error.response.statusText
                    : error.message;
                Swal.fire('Error!', message);
            })
            .then(() => {
                this.setState({working: false});
            });
    }
}

export default App;
