import React from 'react';
import {FontAwesomeIcon, Props as FaProps} from '@fortawesome/react-fontawesome';

interface Props extends FaProps {

}

const Fa: React.FC<Props> = (props) => <FontAwesomeIcon {...props}/>;

export default Fa;
